:root {
  --primary-color: #333333;
  --secondary-color: #333333;
}

body {
  position: relative;
}

.link-to-page:hover {
  color: var(--secondary-color);
}

.link-to-social-media {
  fill: var(--primary-color);
}

.link-to-social-media:hover {
  fill: var(--secondary-color);
}

.carousel-dot > .react-multi-carousel-dot > button {
  background-color: #f2f2f5;
  border: none;
}

.carousel-dot > .react-multi-carousel-dot--active > button {
  background-color: var(--primary-color);
  border: none;
}

.image-paragraph-text a {
  color: var(--primary-color);
  text-decoration: underline;
}

.image-paragraph-text ul li {
  list-style: inside;
}

.image-paragraph-text ol li {
  list-style-type: decimal;
  list-style-position: inside;
}

.break-words > p,
ul,
ol {
  margin-bottom: 10px;
}
