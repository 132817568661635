@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* -------------------- Bulletin -------------------- */
  .bulletin {
    @apply flex flex-col gap-[15px];
  }
  .bulletin-title {
    @apply text-[22px] font-bold;
  }

  /* -------------------- Card -------------------- */
  .card {
    @apply max-w-[180px] min-w-[360px] h-[200px] lg:h-[310px] flex flex-col bg-white rounded-lg border-[#F2F2F5] border-[1px];
  }
  .featured-card {
    @apply w-[300px] h-[250px] lg:w-[360px] lg:h-[310px] flex flex-col items-center justify-between bg-white rounded-lg border-[#F2F2F5] border-[1px];
  }
  .card-image {
    @apply rounded-t-lg h-2/3 w-full bg-cover bg-center bg-no-repeat;
  }
  .card-title {
    @apply uppercase font-bold text-[14px] leading-[19px] lg:text-[18px] lg:leading-[23px];
  }
  .publish-date {
    @apply lg:text-sm text-xs text-left w-full pt-2;
  }
  .card-container {
    @apply flex flex-col items-center justify-center h-1/3 lg:gap-[5px] px-2 py-2;
  }
  .card-xl {
    @apply w-full lg:w-[555px] h-[250px] lg:h-[486px] flex flex-row lg:flex-col justify-between border-[#F2F2F5] border-[1px] rounded-lg;
  }
  .card-xl-container {
    @apply h-full lg:h-1/3 w-2/3 lg:w-full flex flex-col lg:flex-row justify-between lg:justify-start lg:p-[30px] p-4;
  }
  .card-xl-button {
    @apply w-[160px] min-h-fit py-[11px] px-[9px] rounded-[8px] font-bold text-white text-center;
  }
  .card-xl-image {
    @apply lg:rounded-t-lg rounded-l-lg h-full lg:h-2/3 w-2/3 lg:w-full bg-cover bg-center bg-no-repeat;
  }
  .card-xl-title-description {
    @apply h-full w-full lg:w-2/3 flex flex-col gap-[5px] text-[16px];
  }
  .card-xl-title {
    @apply font-bold text-[18px] leading-[22px] lg:text-[22px] lg:leading-[28px];
  }
  .card-xl-price-button-section {
    @apply w-full lg:w-1/3 flex flex-col lg:h-full items-start lg:items-end justify-between;
  }
  .card-xl-price {
    @apply font-bold text-[18px] leading-[22px] lg:text-[28px] lg:leading-[35px];
  }
  .card-xl-image {
    @apply lg:rounded-t-lg rounded-l-lg h-full lg:h-2/3 w-2/3 lg:w-full bg-cover bg-center bg-no-repeat;
  }
  .card-xl-container-inner {
    @apply flex flex-col gap-4;
  }

  /* -------------------- CardsSection -------------------- */
  .cards-section {
    @apply w-full h-fit bg-gray-primary flex flex-col items-center justify-between gap-[15px] lg:gap-[30px] mb-[20px] lg:mb-[40px];
  }
  .cards-section-title {
    @apply font-bold text-[22px] lg:text-[42px] uppercase;
  }
  .featured-card-section-container {
    @apply lg:max-w-[1140px] flex flex-row flex-wrap items-center justify-center gap-[15px] lg:gap-[30px];
  }
  .featured-card-section-container-mobile {
    @apply flex flex-row flex-nowrap gap-[15px] w-full items-start overflow-x-scroll px-[5%];
  }
  .card-section-container {
    @apply lg:w-[1140px] flex flex-row flex-wrap items-center justify-center gap-[30px] lg:gap-y-[60px];
  }
  .card-section-container-mobile {
    @apply flex flex-wrap items-center justify-center gap-[40px] flex-grow px-[15px];
  }
  .cards-section-button {
    @apply max-w-[260px] min-h-fit py-[15px] px-[15px] rounded-[8px] font-bold text-white;
  }

  /* -------------------- Footer -------------------- */
  .footer {
    @apply w-[full] h-[fit] text-white py-[25px] lg:py-[50px] text-[14px] md:text-[16px] flex items-center justify-center;
  }
  .footer-container {
    @apply lg:w-[1140px] w-full h-fit justify-start flex flex-wrap sm:flex-nowrap gap-y-8 gap-x-[10%] lg:gap-x-[15%] px-[15px] lg:px-0;
  }
  .footer-contact-info {
    @apply w-fit break-words flex flex-col gap-[36px];
  }
  .footer-contact-info-container {
    @apply flex flex-col gap-[10px];
  }
  .footer-contact-info-row {
    @apply flex flex-row items-center gap-[10px];
  }
  .footer-navigation {
    @apply max-w-fit break-words flex flex-col gap-[15px];
  }
  .footer-navigation-title {
    @apply text-[22px] font-bold;
  }
  .footer-navigation-container {
    @apply flex flex-col gap-[15px] normal-case;
  }
  .footer-logo {
    @apply max-h-[60px];
  }
  .footer-logo-placeholder {
    @apply h-[60px] w-[60px] bg-gray-primary rounded-lg;
  }
  .footer-bulletin-holder {
    @apply w-full lg:w-3/6;
  }

  /* -------------------- Header -------------------- */
  .header-logo {
    @apply max-h-[40px] lg:max-h-[60px] cursor-pointer;
  }
  .header-logo-placeholder {
    @apply h-[40px] w-[40px] lg:h-[60px] lg:w-[60px] bg-gray-primary rounded-lg;
  }
  .header-top-panel {
    @apply fixed z-50 w-full h-fit flex flex-col items-center justify-between ease-in-out duration-700 bg-white;
  }
  .header-top-panel-inner {
    @apply w-full flex flex-col items-center justify-center;
  }
  .logo-nav-container-holder {
    @apply w-full flex flex-row items-center justify-center px-[15px];
  }
  .logo-nav-container {
    @apply w-[1140px] lg:max-w-[1140px] h-full flex items-center justify-between bg-white py-[20px];
  }
  .logo-nav-container-inner {
    @apply flex flex-row gap-[15px];
  }

  /* -------------------- ImageCarousel -------------------- */
  .carousel {
    @apply flex items-center justify-center;
  }
  .carousel-image {
    @apply md:w-[1140px] w-full h-[130px] md:h-[400px] bg-white;
  }

  /* -------------------- Navigation -------------------- */
  /* -------------------- NavItem -------------------- */
  .link-to-page {
    @apply uppercase font-bold text-lg text-primary-color;
  }
  .mobile-menu-icon-parent-open {
    @apply flex flex-col gap-1 justify-center w-10 h-10 bg-transparent outline-none focus:outline-none z-20;
  }
  .mobile-menu-icon-parent-closed {
    @apply flex flex-col gap-2 justify-center w-10 h-10 bg-transparent outline-none focus:outline-none z-20;
  }
  .line-upper-open {
    @apply w-10 h-1 rounded transition-all duration-300 transform rotate-45 translate-y-1 z-20;
  }
  .line-lower-open {
    @apply w-10 h-1 rounded transition-all duration-300 transform -rotate-45 -translate-y-1 z-20;
  }
  .line-upper-closed {
    @apply w-10 h-1 rounded transition-all duration-300 transform z-20;
  }
  .line-lower-closed {
    @apply w-10 h-1 rounded transition-all duration-300 transform z-20;
  }
  .mobile-menu-holder {
    @apply select-none justify-center z-20 items-end flex flex-col gap-[15px];
  }
  .mobile-submenu {
    @apply w-48 ring-1 ring-black ring-opacity-5 z-10;
  }
  .mobile-submenu-item {
    @apply block px-4 py-2 text-right text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
  }
  .mobile-menu {
    @apply bg-white fixed top-0 right-0 pr-[5%] pl-[15%] py-[25%] w-2/3 h-screen drop-shadow-2xl;
  }
  .menu-item {
    @apply relative;
  }
  .menu-item-dropdown-holder {
    @apply absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10;
  }
  .menu-dropdown-item {
    @apply block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;
  }

  /* -------------------- NewsLetterForm -------------------- */
  .newsletter-form {
    @apply flex flex-col gap-4;
  }
  .newsletter-form-inner {
    @apply w-full lg:w-full relative flex flex-col gap-[10px];
  }
  .newsletter-form-input {
    @apply w-full h-[50px] rounded-[8px] p-3 text-[#333] outline-none;
  }
  .newsletter-form-submit-button {
    @apply w-[80px] px-2 h-[50px] rounded-[7px] absolute right-0 bottom-0 border-2 border-[#fff] font-bold lg:text-[22px] text-[20px] text-white;
  }
  .newsletter-form-terms {
    @apply flex gap-2 items-center flex-nowrap;
  }
  .newsletter-form-terms-checkbox-required {
    @apply flex-shrink-0;
  }

  /* -------------------- RightsReserved -------------------- */
  .rights-reserved-text {
    @apply w-full lg:h-[50px] h-[35px] flex items-center justify-center bg-gray-primary text-[10px] lg:text-[16px];
  }

  /* -------------------- TextWithImageSection -------------------- */
  /* -------------------- TextWithGoogleMapSection -------------------- */

  .image-paragraph,
  .map-paragraph {
    @apply w-full flex items-center justify-center;
  }
  .image-paragraph-text,
  .map-paragraph-text {
    @apply lg:max-w-[1140px] mb-[20px] lg:mb-[40px] text-[14px] lg:text-[16px] gap-[30px] items-start justify-between flex;
  }
  .image-fullscreen-overlay {
    @apply fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50;
  }
  .image-fullscreen-holder {
    @apply relative w-1/2 max-w-full;
  }
  .image {
    @apply w-full h-auto object-cover;
  }
  .exit-fullscreen-button {
    @apply absolute top-[-55px] right-[-55px] bg-white text-black text-xl font-bold border-none cursor-pointer py-2 px-4 rounded-full;
  }

  /* -------------------- TitleSection -------------------- */
  .title-section {
    @apply w-full h-fit min-h-[109px] lg:min-h-[236px] text-white flex flex-col justify-center items-center text-center lg:py-[60px] py-[25px] px-[15px];
  }
  .title-section-title {
    @apply font-bold text-[24px] lg:text-[50px] uppercase;
  }
  .title-section-subtitle {
    @apply text-[18px] lg:text-[36px] text-center;
  }

  /* -------------------- TopPanel -------------------- */
  .top-panel-holder {
    @apply w-full flex justify-center bg-gray-primary px-[15px];
  }
  .top-panel {
    @apply hidden w-full lg:max-w-[1140px] h-[54px] lg:flex lg:flex-row lg:justify-around text-[14px] leading-4 py-[15px];
  }
  .top-panel-element {
    @apply lg:flex min-w-max flex-row items-center gap-[10px];
  }

  /* -------------------- Home -------------------- */
  .home {
    @apply h-full w-full bg-white;
  }

  /* -------------------- Page -------------------- */
  .page {
    @apply h-fit flex flex-col items-center;
  }
  .page-container {
    @apply w-full lg:w-[1140px] px-[15px] pb-[15px] lg:pb-[50px] lg:px-0;
  }
  .page-title {
    @apply text-center font-bold text-[36px] lg:text-[50px] leading-[45px] lg:leading-[59px] my-[20px] lg:my-[40px];
  }
  .page-subtitle {
    @apply text-left font-normal leading-[36px] lg:leading-[44px] text-[26px] lg:text-[34px] mb-[10px];
  }
  .paragraph {
    @apply text-justify break-words text-[16px] font-normal leading-[24px] mb-[10px];
  }

  /* -------------------- Main -------------------- */
  .main {
    @apply font-open-sans overflow-x-hidden min-h-screen h-fit flex flex-col justify-between pt-[94px] lg:pt-[142px] bg-white;
  }
}
